<script lang="ts" setup>
//TODO: add correct type
const props = defineProps<{
  content: any;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slotContent = getSlotContent('grid');
</script>

<template>
  <div>
    <CmsGenericElement v-if="slotContent" :content="slotContent" />
  </div>
</template>
